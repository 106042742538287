/* You can add global styles to this file, and also import other style files */

.mainContent
{
  padding: 1%;
  margin-bottom: 30pt;
}

.menuitem
{
  width: 100%;
  border-bottom-color: rgba(255, 255, 255, 0.9);
  border-bottom-style: solid;
  border-bottom-width: 1pt;
  padding: 13px 16px;
}

.menuitem a, .menuitem svg
{
  color: rgba(255, 255, 255, 0.9);
  font-family: Lato, "Helvetica Neue", Arial, serif;
  font-size: 14px;
  text-decoration: none;
  vertical-align: bottom;
}

.menuitem a
{
  display: block;
}

.menuitem svg
{
  float: right;
  fill: white;
  height: 1.6em;
  width: 1.6em;
}

.withborder
{
  margin-left: 1%;
  margin-top: 1%;
  padding-top: 0.5%;
}

.contentTabBorder
{
  border-left: 1pt solid #dee2e6;
  border-bottom: 1pt solid #dee2e6;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
}

.contentTextDivBox
{
  float: left;
  margin-bottom: 1%;
}

.form-button
{
  margin-right: 5pt;
}

.value
{
  font-size: 2em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: 400;
  line-height: 28px;
}

.label
{
  color: rgba(0, 0, 0, 0.87);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.label td, .value td
{
  padding-right: 25px;
}

.signal-table th
{
  background-color: rgb(249, 250, 251);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
}

.table a
{
  text-decoration: none;
}

.card
{
  float: left;
  margin-right: 1%;
  margin-bottom: 1%;
}

.blue
{
  color: rgb(96, 168, 221);
}

.green
{
  color: rgb(33, 186, 69);
}

.orange
{
  color: rgb(244, 141, 72);
}

.purple
{
  color: rgb(164, 52, 200);
}

.red
{
  color: red;
}

.search-result-card
{
  width: 50%;
  margin-top: 0.75%;
}
